import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'UsersCreate',
    data() {
        return {
            user: {
                first_name: null,
                last_name: null,
                email: null,
                password: null,
                role: null
            }
        }
    },
    components: {
        adminLayout
    },
    methods: {
        createUser() {
            const self = this
            
            httpAxios({
                url: self.$backendUrl + '/api/v1/users',
                method: 'POST',
                data: self.user
            }).then(function() {
                self.$router.push({ name: 'admin.users' })

                self.notifySuccess()
            })
        },
        handleFileAudioUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAudio = null
            }

            this.fileAudio = files[0]
        },
        handleFileCoverImageUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileCoverImage = null
            }

            this.fileCoverImage = files[0]
        }
    }
}